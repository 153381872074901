import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Button, Input, Select, Row, Col, Tag, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const AssistantSettings = () => {
    const [settings, setSettings] = useState([]);
    const [selectedSettingId, setSelectedSettingId] = useState('');
    const [promptText, setPromptText] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        const fetchSettings = async () => {
            const { data: assistantSettings, error } = await supabase
                .from('assistant_settings')
                .select('*')
                .order('active', { ascending: false }) // Stellen Sie sicher, dass aktive Einstellungen zuerst kommen
                .order('modified_at', { ascending: false });
    
            if (error) {
                console.error('Error fetching settings:', error);
                message.error('Failed to fetch settings.');
            } else {
                setSettings(assistantSettings);
                // Wählen Sie automatisch den ersten aktiven Eintrag, falls vorhanden
                if (assistantSettings && assistantSettings.length > 0) {
                    const activeSetting = assistantSettings.find(setting => setting.active);
                    if (activeSetting) {
                        setSelectedSettingId(activeSetting.id);
                        setPromptText(activeSetting.prompt);
                        setName(activeSetting.name);
                    } else {
                        // Falls keine aktive Einstellung gefunden wurde, wählen Sie standardmäßig den ersten Eintrag
                        setSelectedSettingId(assistantSettings[0].id);
                        setPromptText(assistantSettings[0].prompt);
                        setName(assistantSettings[0].name);
                    }
                }
            }
        };
    
        fetchSettings();
        // Richten Sie hier eventuell benötigte Real-Time-Subscription ein
    
        // Vergessen Sie nicht, die Subscription am Ende aufzuräumen
        return () => {
            // Bereinigen der Subscription, falls notwendig
        };
    }, []);

    const fetchSettings = async (callback) => {
        const { data: assistantSettings, error } = await supabase
            .from('assistant_settings')
            .select('*')
            .order('active', { ascending: false })
            .order('modified_at', { ascending: false });
    
        if (error) {
            console.error('Error fetching settings:', error);
            message.error('Failed to fetch settings.');
        } else {
            setSettings(assistantSettings);
            // Rufen Sie die Callback-Funktion auf, falls eine bereitgestellt wurde
            if (callback) callback(assistantSettings);
        }
    };

    const sendWebhook = async (prompt) => {
        try {
            const response = await fetch('https://flow002.lvit.io/webhook/fc96ef2f-411c-4764-b29a-92a789f7ab0c', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt }),
            });

            if (!response.ok) throw new Error('Webhook failed');
            console.log('Webhook sent successfully');
        } catch (error) {
            console.error('Error sending webhook:', error);
        }
    };

    const updatePromptText = async (activate = false) => {
        if (!selectedSettingId) return;

        const updates = {
            name,
            prompt: promptText,
            modified_at: new Date().toISOString(),
            ...(activate && { active: true }),
        };

        if (activate) {
            // Stelle sicher, dass du die Fehlerbehandlung für diese Operation ebenfalls implementierst
            const { error: deactivateError } = await supabase
                .from('assistant_settings')
                .update({ active: false })
                .neq('id', selectedSettingId);

            if (deactivateError) {
                console.error('Error deactivating other prompts:', deactivateError);
                return; // Stoppe die Ausführung der Funktion, wenn ein Fehler auftritt
            }
        }

        // Hier extrahieren wir die Variable `error` aus der Antwort der Update-Operation
        const { error } = await supabase
            .from('assistant_settings')
            .update(updates)
            .eq('id', selectedSettingId);

        if (error) {
            console.error('Error updating instruction:', error);
        } else {
            fetchSettings(); // Einstellungen neu laden
            message.success('Instruction updated successfully.');
            if (activate) {
                sendWebhook(promptText); // Webhook senden, wenn aktiviert
            }
        }
    };




    const handleAddNewSetting = async () => {
        const newSetting = {
            name: 'New Setting',
            prompt: '',
            active: false,
            modified_at: new Date().toISOString(),
            created_at: new Date().toISOString()
        };
        const { data, error } = await supabase
            .from('assistant_settings')
            .insert([newSetting])
            .select(); // .select() hinzufügen, um den eingefügten Datensatz zu erhalten
    
        if (error) {
            console.error('Error adding new setting:', error);
            message.error('Failed to add a new setting.');
            return;
        }
    
        if (data && data.length > 0) {
            const addedSetting = data[0];
            console.log('Added setting:', addedSetting);
    
            // Update UI to reflect the new setting
            setSelectedSettingId(addedSetting.id); // Verwenden Sie die ID des neu hinzugefügten Settings
            setPromptText(addedSetting.prompt);
            setName(addedSetting.name);
            fetchSettings(); // Aktualisieren Sie die Einstellungen, um die neueste Liste anzuzeigen
            message.success('New instruction added successfully.');
        } else {
            message.error('New instruction was added, but details could not be retrieved.');
        }
    };
    
    
    

    return (
        <div className="assistantSettingsContainer">
            <div className="assistantSettingsForm">
                <Row  style={{ marginBottom: '35px', width: '100%', position: 'relative' }}> 
                    
                        <Select
                            value={selectedSettingId}
                            style={{ width: '100%' }} // Nutzt die volle Breite der Col-Komponente
                            onChange={value => {
                                const setting = settings.find(s => s.id === value);
                                setSelectedSettingId(value);
                                setPromptText(setting.prompt);
                                setName(setting.name);
                            }}
                        >
                            {settings.map(setting => (
                                <Option key={setting.id} value={setting.id}>
                                    {setting.active ? <Tag color="green">Active</Tag> : null}
                                    {`${setting.name} | ${new Date(setting.modified_at).toLocaleString()}`}
                                </Option>
                            ))}
                        </Select>
                    
                        <Button className="addPromptButton" type="primary" icon={<PlusOutlined />} onClick={handleAddNewSetting} style={{ width: '100%' }} />
                    
                </Row>
                <Input
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="Name"
                    style={{ marginBottom: '18px' }}
                />
                <Input.TextArea
                    value={promptText}
                    onChange={e => setPromptText(e.target.value)}
                    rows={10}
                    placeholder="Edit instruction here"
                    style={{ marginBottom: '20px' }} // Stil hinzugefügt für Konsistenz
                />
                <Row gutter={8}>
                    <Col>
                        <Button onClick={() => updatePromptText(false)} type="primary">
                            Update
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => updatePromptText(true)} type="primary">
                            Update & Activate
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );

};

export default AssistantSettings;
