import React, { useMemo } from 'react';
import {
  LineChart, Line, BarChart, Bar, PieChart, Pie, RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis,
  ScatterChart, Scatter, Tooltip, Legend, ResponsiveContainer, XAxis, YAxis, AreaChart, Area, CartesianGrid, Cell
} from 'recharts';

const defaultColors = ['#8884d8', '#82ca9d', '#8dd1e1', '#a4de6c', '#d0ed57', '#ffc658'];

const ChartComponent = ({ jsonData }) => {
  const {
    chart_type,
    data,
    legend,
    colors,
    axes,
  } = useMemo(() => {
    const parsedJsonData = typeof jsonData === 'string' ? JSON.parse(jsonData) : jsonData;
    const parsedData = typeof parsedJsonData.data === 'string' ? JSON.parse(parsedJsonData.data) : parsedJsonData.data;
    const parsedColors = parsedJsonData.colors ? JSON.parse(parsedJsonData.colors) : defaultColors;
    const parsedAxes = parsedJsonData.axes && typeof parsedJsonData.axes === 'string' ? JSON.parse(parsedJsonData.axes) : {
      xAxis: { dataKey: "defaultXDataKey", title: "Default X-Axis Title" },
      yAxis: { title: "Default Y-Axis Title" }
    };

    return {
      chart_type: parsedJsonData.chart_type,
      data: parsedData,
      legend: parsedJsonData.legend === "true",
      tooltip: parsedJsonData.tooltip === "true",
      colors: parsedColors,
      axes: parsedAxes,
    };
  }, [jsonData]);

  const renderChart = () => {
    switch (chart_type) {
      case 'line':
        return (
          <LineChart data={data} margin={{ top: 50, right: 30, bottom: 5, left: 20 }}>
            <XAxis dataKey={axes.xAxis.dataKey} />
            <YAxis />
            <Tooltip />
            {legend && <Legend />}
            {Object.keys(data[0])
              .filter((key, index) => index > 0) // Exclude the first key (used for the X-axis)
              .map((key, index) => (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={key}
                  stroke={colors[index % colors.length]} // Cycle through the colors array
                />
              ))}
          </LineChart>
        );
      case 'bar':
        return (
          <BarChart data={data} margin={{ top: 5, right: 30, bottom: 5, left: 20 }}>
            <XAxis dataKey={axes.xAxis.dataKey} />
            <YAxis />
            <Tooltip />
            {legend && <Legend />}
            <Bar dataKey={Object.keys(data[0])[1]} fill={colors[0]} />
            {Object.keys(data[0]).length > 2 &&
              <Bar dataKey={Object.keys(data[0])[2]} fill={colors[1]} />}
          </BarChart>
        );
      case 'pie':
        return (
          <PieChart>
            <Pie
              data={data}
              dataKey={Object.keys(data[0])[1]}
              nameKey="category"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={(entry) => entry.name}
              labelLine={false}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip />
            {legend && <Legend />}
          </PieChart>
        );

      case 'radar':
        return (
          <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey={axes.xAxis.dataKey} />
            <PolarRadiusAxis />
            <Radar name={Object.keys(data[0])[1]} dataKey={Object.keys(data[0])[1]} stroke={colors[0]} fill={colors[0]} fillOpacity={0.6} />
            {Object.keys(data[0]).length > 2 &&
              <Radar name={Object.keys(data[0])[2]} dataKey={Object.keys(data[0])[2]} stroke={colors[1]} fill={colors[1]} fillOpacity={0.6} />}
            <Tooltip />
            {legend && <Legend />}
          </RadarChart>
        );
      case 'scatter':
        return (
          <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <XAxis dataKey={axes.xAxis.dataKey} name={axes.xAxis.title} />
            <YAxis name={axes.yAxis.title} />
            <Tooltip />
            {legend && <Legend />}
            {Object.keys(data[0]).slice(1).map((key, index) => (
              <Scatter key={index} name={key} data={data} fill={colors[index % colors.length]} dataKey={key} />
            ))}
          </ScatterChart>
        );

      case 'area':
        return (
          <AreaChart width={730} height={250} data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
              {colors.map((color, index) => (
                <linearGradient key={index} id={`color${index}`} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={color} stopOpacity={0} />
                </linearGradient>
              ))}
            </defs>
            <XAxis dataKey={axes.xAxis.dataKey} />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            {legend && <Legend />}
            {Object.keys(data[0]).slice(1).map((key, index) => (
              <Area type="monotone" dataKey={key} stroke={colors[index]} fillOpacity={1} fill={`url(#color${index})`} />
            ))}
          </AreaChart>
        );
      default:
        return <div>Unsupported chart type</div>;
    }
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      {renderChart()}
    </ResponsiveContainer>
  );
};

export default ChartComponent;
