import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Spin, Drawer, Button } from 'antd';
import { UserOutlined, MenuOutlined, SettingOutlined } from '@ant-design/icons';
import MessageList from './components/MessageList';
import ChatInput from './components/ChatInput';
import ThreadList from './components/ThreadList';
import UserSettings from './components/UserSettings';
import AssistantSettings from './components/AssistantSettings';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useTheme } from './ThemeContext';
import './index.css';
import { supabase } from './supabaseClient';
import {
  loadThreadsLogic,
  loadMessagesLogic,
  handleSelectThreadLogic,
  handleSendMessageLogic,
  handleCreateThreadLogic,
  subscribeToMessages,
  subscribeToThreadNameChanges,
  handleThreadNameChange
} from './appLogic';

const { Content, Sider } = Layout;

function App() {
  const theme = useTheme();
  const [threads, setThreads] = useState([]);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [userSettingsVisible, setUserSettingsVisible] = useState(false);

  const isMobile = window.innerWidth < 768;

  const ADMIN_USERS = [
    'ediz.erkmen@optiwiser.de',
    'maximilian.koehler@optiwiser.de',
    'rv@lvit.de',
  ];


  const handleLoadingChange = (loadingStatus) => {
    setIsLoading(loadingStatus);
  };

  const toggleUserSettings = () => {
    setUserSettingsVisible(!userSettingsVisible);
  };

  const [settingsVisible, setSettingsVisible] = useState(false);

  const handleUserSettingsClick = (event) => {
    event.stopPropagation(); // Verhindert die Weiterleitung des Events an die Sidebar
    setUserSettingsVisible(!userSettingsVisible); // Umschalten der Sichtbarkeit
  };

  // Funktion, um das Öffnen der Assistant Settings zu handhaben
  const handleAssistantSettingsClick = (event) => {
    event.stopPropagation(); // Verhindert die Weiterleitung des Events an die Sidebar
    setSettingsVisible(!settingsVisible); // Umschalten der Sichtbarkeit
  };

  const handleSidebarClick = () => {
    setSettingsVisible(false);
  };


  useEffect(() => {
    const currentThread = threads.find(thread => thread.id === selectedThreadId);
    if (currentThread) {
      document.title = currentThread.name + " | OptiwiserGPT";
    } else {
      document.title = "OptiwiserGPT";
    }
  }, [selectedThreadId, threads]);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const [userDetails, setUserDetails] = useState({ firstName: '', lastName: '' });

  // Funktion zum Holen der Benutzereinstellungen
  const fetchUserSettings = useCallback(async () => {
    if (session) {
      const { data, error } = await supabase
        .from('user_settings')
        .select('first_name, last_name')
        .eq('user_id', session.user.id);

      if (error) {
        console.error('Fehler beim Abrufen der Benutzereinstellungen', error);
      } else if (data && data.length > 0) {
        setUserDetails({
          firstName: data[0].first_name,
          lastName: data[0].last_name,
          email: session.user.email, // E-Mail des Benutzers setzen
        });
      } else {
        console.log('Keine Benutzereinstellungen gefunden.');
      }
    }
  }, [session]);


  useEffect(() => {
    fetchUserSettings();
  }, [fetchUserSettings]); // fetchUserSettings als Abhängigkeit


  const userName = `${userDetails.firstName} ${userDetails.lastName}`.trim() || 'Unbekannt';
  const userId = session?.user?.id;

  useEffect(() => {
    if (session) {
      loadThreadsLogic(setThreads);
    }
  }, [session]);

  useEffect(() => {
    loadMessagesLogic(selectedThreadId, setMessages);
  }, [selectedThreadId]);

  useEffect(() => {
    let unsubscribeFunc = () => { };
    if (selectedThreadId) {
      unsubscribeFunc = subscribeToMessages(selectedThreadId, setMessages);
    }
    return () => unsubscribeFunc();
  }, [selectedThreadId, setMessages]);

  useEffect(() => {
    const unsubscribe = subscribeToThreadNameChanges(selectedThreadId, () => handleThreadNameChange(setThreads));
    return () => unsubscribe && unsubscribe();
  }, [selectedThreadId]);

  const handleSelectThread = handleSelectThreadLogic(setSelectedThreadId, setMessages);

  const handleSendMessage = (message) => {
    handleSendMessageLogic(selectedThreadId, setSelectedThreadId, setThreads, setMessages, message, session);
  };

  const handleCreateThread = () => {
    handleCreateThreadLogic(setThreads, 'Neuer Thread', session, setSelectedThreadId);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };


  if (loading) {
    return <Spin size="large" />;
  }

  if (!session) {
    return (
      <div className="auth-wrapper">
        <div className="auth-form">
          <h1 className="auth-title">Anmelden</h1>
          <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} providers={[]} showLinks={false} localization={{
            variables: {
              sign_in: {
                email_label: 'E-Mail Adresse',
                password_label: 'Passwort',
                email_input_placeholder: 'user@optiwiser.de',
                password_input_placeholder: 'Passwort eingeben',
                button_label: 'Anmelden',
              },
            },
          }} />
        </div>
      </div>
    );
  }

  return (
    <Layout className={`appLayout ${theme}`}>
      {settingsVisible && ADMIN_USERS.includes(userDetails.email) && <AssistantSettings />}
      <Layout className="innerLayout">
        {isMobile && (
          <>
            <Button className="menuButton" type="primary" icon={<MenuOutlined />} />
            <Drawer onClick={toggleDrawer}
              title="Threads"
              placement="left"
              closable={true}
              onClose={toggleDrawer}
              visible={drawerVisible}
            >
              <ThreadList
                threads={threads}
                onSelectThread={handleSelectThread}
                selectedThreadId={selectedThreadId}
                onCreateThread={handleCreateThread}
              />
            </Drawer>
          </>
        )}
        {!isMobile && (
          <Sider className="sider sidebar" onClick={handleSidebarClick}>
            <ThreadList
              threads={threads}
              onSelectThread={handleSelectThread}
              selectedThreadId={selectedThreadId}
              onCreateThread={handleCreateThread}
            />
            <div className='UserAndSettings' style={{ position: 'relative' }}>
              <Button icon={<UserOutlined />} className="UserInfoButton" onClick={handleUserSettingsClick}>{userName}</Button>
              {ADMIN_USERS.includes(userDetails.email) && (
                <Button icon={<SettingOutlined />} className="SettingsButton" onClick={handleAssistantSettingsClick} />
              )}

            </div>
          </Sider>

        )}
        <Layout className="contentLayout">
          <Content className="content">
            <MessageList
              messages={messages}
              onLoadingChange={handleLoadingChange}
              userDetails={userDetails} // userDetails als Prop übergeben
            />
          </Content>
          <div className="chatInputContainer">
            <ChatInput onSendMessage={handleSendMessage} isLoading={isLoading} selectedThreadId={selectedThreadId} />
          </div>
          <UserSettings
            isVisible={userSettingsVisible}
            onClose={toggleUserSettings}
            userId={userId} // Übergebe die Benutzer-ID als Prop
          />
        </Layout>
      </Layout>
    </Layout>
  );
}

export default App;