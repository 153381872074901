import React, { useState } from 'react';
import { Form, Input, Select, Button } from 'antd';

const { Option } = Select;

const CustomForm = ({ formContent, messageId, updateMessageContent }) => { // Hier deklarieren wir `updateMessageContent` als Prop
    const [isSent, setIsSent] = useState(formContent.status === 'sent');
    const confirmationMessage = formContent.confirmation || '';
    
    const onFinish = async (values) => {
        updateMessageContent(messageId, values);
        console.log('Received values of form: ', values);

        // Daten für den Webhook-Aufruf vorbereiten, einschließlich der Message-ID
        const dataToSend = {
            ...values,
            messageId: messageId, // Stelle sicher, dass messageId hier eingefügt wird
        };

        // URL des Webhooks
        const webhookUrl = 'https://flow002.lvit.io/webhook/1583bbf6-f0af-4e2c-b814-e84b692fe5bd';

        try {
            // Versuche, die Daten an den Webhook zu senden
            const response = await fetch(webhookUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });

            if (!response.ok) {
                // Behandlung von Netzwerkfehlern oder Fehlern vom Server
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const responseData = await response.json();
            console.log('Webhook response:', responseData);
            setIsSent(true);
            // Hier könnten Sie zusätzliche Aktionen basierend auf der Antwort implementieren
        } catch (error) {
            console.error('Fehler beim Senden der Daten an den Webhook:', error);
        }
    };

    if (isSent) {
        // Zeige die Bestätigungsnachricht, wenn der Status 'sent' ist
        return <div className="form-notification">✅ {confirmationMessage || '👓 Anfrage wird verarbeitet.'}</div>;
    }

    return (
        <Form
            className="custom_form"
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
                ...formContent.fields.reduce((acc, field) => {
                    acc[field.field] = field.value;
                    return acc;
                }, {}),
            }}
        >
            {formContent.fields.map((field, index) => {
                switch (field.type) {
                    case 'text':
                        return (
                            <Form.Item
                                key={index}
                                label={field.label}
                                name={field.field}
                                rules={[{ required: true, message: `Bitte ${field.label} eingeben!` }]}
                            >
                                <Input placeholder={field.placeholder} />
                            </Form.Item>
                        );
                    case 'textarea':
                        return (
                            <Form.Item
                                key={index}
                                label={field.label}
                                name={field.field}
                                rules={[{ required: true, message: `Bitte ${field.label} eingeben!` }]}
                            >
                                <Input.TextArea rows={4} placeholder={field.placeholder} />
                            </Form.Item>
                        );
                    case 'select':
                        return (
                            <Form.Item
                                key={index}
                                label={field.label}
                                name={field.field}
                                rules={[{ required: true, message: `Bitte ${field.label} auswählen!` }]}
                            >
                                <Select placeholder="Bitte wählen">
                                    {field.options.map((option, idx) => (
                                        <Option key={idx} value={option}>
                                            {option}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        );
                    default:
                        return null;
                }
            })}
            <Form.Item>
                <Button type="primary" htmlType="submit" className="form-submit-button">
                    Absenden
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CustomForm;
