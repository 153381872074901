import { fetchThreads, fetchMessages, postMessage, createThread } from './api';
import { supabase } from './supabaseClient';

export const loadThreadsLogic = async (setThreads) => {
    console.log('loadThreadsLogic setThreads type:', typeof setThreads); // Sollte "function" anzeigen
    try {
        const threads = await fetchThreads();
        console.log('Threads loaded:', threads);
        setThreads(threads);
    } catch (error) {
        console.error('Fehler beim Laden der Threads', error);
    }
};


export const loadMessagesLogic = async (selectedThreadId, setMessages) => {
    if (!selectedThreadId) return;
    try {
        const messages = await fetchMessages(selectedThreadId);
        if (typeof setMessages !== 'function') {
            console.error('setMessages ist keine Funktion', setMessages);
            return;
        }
        setMessages(messages);
    } catch (error) {
        console.error('Fehler beim Laden der Nachrichten', error);
    }
};


let currentSubscription = null;

export const subscribeToMessages = (selectedThreadId, setMessages) => {
    console.log('Versuche, Echtzeit-Updates zu abonnieren...');

    if (currentSubscription) {
        console.log('Beende das vorhandene Abonnement...');
        //supabase.removeSubscription(currentSubscription);
        currentSubscription = null;
    }

    const handleNewMessage = (payload) => {
        console.log('Neue Nachricht erhalten:', payload);
        // Laden Sie die Nachrichten für den aktuellen Thread neu, um die neue Nachricht einzuschließen
        loadMessagesLogic(selectedThreadId, setMessages);
    };

    console.log('Einrichten des Echtzeit-Abonnements...');
    currentSubscription = supabase
        .channel('public:messages')
        .on('postgres_changes', {
            event: '*',
            schema: 'public',
            table: 'messages',
            filter: `thread=eq.${selectedThreadId}`,
        }, handleNewMessage)
        .subscribe();

    currentSubscription.on('SUBSCRIBED', () => {
        console.log('Erfolgreich abonniert!');
    });

    currentSubscription.on('ERROR', (error) => {
        console.error('Fehler beim Abonnieren:', error);
    });

    return () => {
        console.log('Abonnement wird beendet...');
        if (currentSubscription) {
            //supabase.removeSubscription(currentSubscription);
        }
    };
};

let threadNameChangeSubscription = null;

export const subscribeToThreadNameChanges = (selectedThreadId, handleThreadNameChange) => {
    // Entferne eine bestehende Subscription, falls vorhanden
    if (threadNameChangeSubscription) {
        //supabase.removeSubscription(threadNameChangeSubscription);
        threadNameChangeSubscription = null;
    }

    if (!selectedThreadId) return;

    console.log('Einrichten der Echtzeit-Subscription für Thread-Namensänderungen...');
    threadNameChangeSubscription = supabase
        .channel('public:threads')
        .on('postgres_changes', {
            event: 'UPDATE',
            schema: 'public',
            table: 'threads',
        }, payload => {
            console.log('Thread-Namensänderung erkannt:', payload);
            if (payload.new && payload.new.id === selectedThreadId) {
                // Führe die Callback-Funktion aus, um den Namen im UI zu aktualisieren
                handleThreadNameChange(payload.new.name);
            }
        })
        .subscribe();

    threadNameChangeSubscription.on('SUBSCRIBED', () => {
        console.log('Erfolgreich abonniert für Thread-Namensänderungen!');
    });

    // Behandle Fehler
    threadNameChangeSubscription.on('ERROR', error => {
        console.error('Fehler beim Abonnieren von Thread-Namensänderungen:', error);
    });

    return () => {
        // Diese Funktion kann aufgerufen werden, um die Subscription zu entfernen
        if (threadNameChangeSubscription) {
            console.log('Abonnement für Thread-Namensänderungen wird beendet...');
            //supabase.removeSubscription(threadNameChangeSubscription);
        }
    };
};

export const handleThreadNameChange = (setThreads) => {
    console.log("Änderung am Thread-Namen erkannt, lade Threads neu...");
    loadThreadsLogic(setThreads);
};

export const handleSelectThreadLogic = (setSelectedThreadId, setMessages) => async (threadId) => {
    setSelectedThreadId(threadId);
    await loadMessagesLogic(threadId, setMessages); // Stellen Sie sicher, dass setMessages hier korrekt ist
};


export const handleSendMessageLogic = async (selectedThreadId, setSelectedThreadId, setThreads, setMessages, message, session) => {
    if (message.trim() === '') return;

    let threadId = selectedThreadId;
    if (!threadId) {
        if (!session) return; // Stelle sicher, dass eine Session vorhanden ist

        const userId = session.user.id;
        try {
            await createThread("Neuer Thread", userId); // Erstellt den Thread
            const threads = await fetchThreads(); // Lade alle Threads neu
            setThreads(threads); // Aktualisiere die Thread-Liste im Zustand
            if (threads.length > 0) {
                setSelectedThreadId(threads[0].id); // Wähle den neuesten Thread aus
                threadId = threads[0].id;
            }
        } catch (error) {
            console.error('Fehler beim Erstellen des neuen Threads', error);
        }
    }

    try {
        await postMessage(threadId, message);
        await loadMessagesLogic(threadId, setMessages); // Lädt Nachrichten für den aktuellen Thread neu
    } catch (error) {
        console.error('Fehler beim Senden der Nachricht', error);
    }
};

export const handleCreateThreadLogic = async (setThreads, threadName, session, setSelectedThreadId) => {
    if (!session) return; // Stelle sicher, dass eine Session vorhanden ist

    const userId = session.user.id;
    try {
        await createThread(threadName, userId); // Erstellt den Thread
        const threads = await fetchThreads(); // Lade alle Threads neu
        setThreads(threads); // Aktualisiere die Thread-Liste im Zustand
        if (threads.length > 0) {
            setSelectedThreadId(threads[0].id); // Wähle den neuesten Thread aus
        }
    } catch (error) {
        console.error('Fehler beim Erstellen des neuen Threads', error);
    }
};