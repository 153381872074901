// api.js
import { supabase } from './supabaseClient';

export const fetchThreads = async () => {
  let { data: threads, error } = await supabase
    .from('threads')
    .select('*')
    .order('created_at', { ascending: false }); // Stellen Sie sicher, dass diese Zeile korrekt ist
  if (error) throw new Error(error.message);
  return threads;
};


export const fetchMessages = async (thread) => {
  let { data: messages, error } = await supabase
    .from('messages')
    .select('*')
    .eq('thread', thread)
    .order('created_at', { ascending: true }); // Sortiert die Nachrichten nach dem createdAt-Datum, neueste zuerst
  if (error) throw new Error(error.message);
  return messages;
};


export const postMessage = async (thread, content) => {
  const { error } = await supabase.from('messages').insert([
    { thread, content, role: 'user', type: 'message' } // Beachte die Anpassung der Spaltenbezeichnung
  ]);
  if (error) throw new Error(error.message);
};

export const createThread = async () => {
  let { data: newThread, error } = await supabase
    .from('threads')
    .insert([{ name: 'Neuer Thread' }])
    .single();
  if (error) throw new Error(error.message);
  return newThread;
};

